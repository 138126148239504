<template>
  <p-dialog
    modal
    :visible="visible"
    :closable="false"
    class="app-confirm-dialog"
    :class="`app-confirm-dialog--${color}`"
    :block-scroll="blockScroll"
    :position="position"
    :style="style"
    v-bind="$attrs"
  >
    <template
      v-if="icon"
      #header
    >
      <span class="app-confirm-dialog__icon">
        <app-icon
          :name="icon"
          :color="false"
        />
      </span>
    </template>
    <template #default>
      <h3
        v-if="header"
        class="app-confirm-dialog__title"
      >
        {{ header }}
      </h3>
      <p
        v-if="message"
        class="app-confirm-dialog__message"
      >
        {{ message }}
      </p>

      <div class="app-confirm-dialog__body">
        <slot
          v-if="$slots.body"
          name="body"
        />
      </div>

      <button
        class="app-confirm-dialog__mobile-close-button"
        @click="reject"
      >
        <app-icon
          class="app-confirm-dialog__mobile-close-button-icon"
          name="x"
          size="32"
          color="surface-card"
        />
      </button>
    </template>

    <template
      #footer
    >
      <slot
        v-if="$slots.footer"
        name="footer"
        v-bind="{ reject }"
      />

      <template v-else>
        <div :class="{'flex flex-column': $slots.appendFooter, 'flex-shrink-0 flex-grow-1': !$slots.appendFooter}">
          <div class="flex confirm-dialog-buttons-container">
            <p-button
              v-if="confirmation.reject"
              class="app-confirm-dialog__btn p-button-secondary"
              @click="reject"
            >
              {{ rejectLabel }}
            </p-button>
            <p-button
              v-if="confirmation.accept"
              class="app-confirm-dialog__btn app-confirm-dialog__btn-accept"
              :class="`p-button-${color}`"
              @click="accept"
            >
              {{ acceptLabel }}
            </p-button>
          </div>

          <div
            v-if="$slots.appendFooter"
            class="flex"
          >
            <slot
              name="appendFooter"
            />
          </div>
        </div>
      </template>
    </template>
  </p-dialog>
</template>

<script lang="ts" setup>
// @ts-ignore: ??
import ConfirmationEventBus from 'primevue/confirmationeventbus/confirmationeventbus.esm.js';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import { ConfirmDialogOptions } from '../models';

const props = withDefaults(defineProps<{
  group?: string
  onSubmit?: () => Promise<unknown>
}>(), {
  group: 'default',
  onSubmit: () => new Promise(resolve => {
    resolve(true);
  }),
});

const mq = useMq();

const confirmation = ref<ConfirmDialogOptions | null>(null);
const visible = ref(false);

const icon = computed(() => confirmation.value?.icon);
const header = computed(() => confirmation.value?.header);
const message = computed(() => confirmation.value?.message);
const style = computed(() => confirmation.value?.style ?? {});
const rejectLabel = computed(() => confirmation.value?.rejectLabel ?? 'Cancel');
const acceptLabel = computed(() => confirmation.value?.acceptLabel ?? 'Accept');
const blockScroll = computed(() => confirmation.value?.blockScroll);
const position = computed(() => {
  if (!confirmation.value?.position && (mq.smMinus && !mq.sm)) return 'bottom';
  return confirmation.value?.position;
});
const color = computed(() => confirmation.value?.color);

const confirmListener = (options: ConfirmDialogOptions) => {
  if (!options) {
    return;
  }
  if (options.group === props.group) {
    confirmation.value = options;
    visible.value = true;
  }
};
const closeListener = () => {
  visible.value = false;
  confirmation.value = null;
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  ConfirmationEventBus.on('confirm', confirmListener);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  ConfirmationEventBus.on('close', closeListener);
});
onBeforeUnmount(() => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  ConfirmationEventBus.off('confirm', confirmListener);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  ConfirmationEventBus.off('close', closeListener);
});

async function accept() {
  if (confirmation.value?.accept) {
    if (props.onSubmit && !await props.onSubmit()) {
      return;
    }

    confirmation.value.accept();
  }

  visible.value = false;
}
function reject() {
  if (confirmation.value?.reject) {
    confirmation.value.reject();
  }
  visible.value = false;
}
</script>
