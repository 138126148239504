import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, split } from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { Kind, OperationTypeNode, print } from 'graphql';
import { createClient } from 'graphql-ws';

import { recordGraphQL } from '@/util/openreplay';

import { CACHE_OPTIONS } from './cache';
import { persistenceMapper } from './persistence-mapper';
import { getApiUrl } from './url';

export { mapWsEvents } from './ws-events';

/**
 * Cache
 */
export const cache = new InMemoryCache(CACHE_OPTIONS);

export const persistor = new CachePersistor({
  cache,
  persistenceMapper,
  storage: new LocalStorageWrapper(window.localStorage),
});

/**
 * WS Client
 */
export const wsClient = createClient({
  lazy: false,
  keepAlive: 30e3,
  url: getApiUrl('ws'),
  jsonMessageReviver: (key: string, value: unknown) => {
    if (!apolloClient) {
      return value;
    }

    const validObject = typeof value === 'object' && value !== null;

    if (validObject && key.startsWith('deletedOne')) {
      const entity = key.replace('deletedOne', '');

      apolloClient.cache.evict({
        id: apolloClient.cache.identify({
          ...value,
          __typename: entity,
        }),
      });
      apolloClient.cache.gc();
    }

    return value;
  },
  shouldRetry: () => true,
  retryAttempts: Number.POSITIVE_INFINITY,
});

wsClient.on('connected', (ws, payload) => {
  if (payload?.version && payload.version) localStorage.versionFromApi = payload.version;
});

/**
 * Links
 */
const wsLink = new GraphQLWsLink(wsClient);

// const errorLink = onError(globalErrorHandler);

const httpLink = new HttpLink({
  uri: getApiUrl(),
  credentials: 'include',
});

const persistedLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
  async generateHash(document) {
    const hash = new Sha256();
    hash.update(print(document));

    return arr2hex(await hash.digest());
  },
}).concat(httpLink);

const trackerApolloLink = new ApolloLink((operation, forward) => forward(operation).map(result => {
  const operationDefinition = operation.query.definitions[0];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return recordGraphQL(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    operationDefinition.kind === 'OperationDefinition' ? operationDefinition.operation : 'unknown?',
    operation.operationName,
    operation.variables,
    result,
  );
}));

// use http link for specified operations
const link = split(
  ({ query }) => {
    const d = getMainDefinition(query);

    return d.kind === Kind.OPERATION_DEFINITION && d.operation === OperationTypeNode.SUBSCRIPTION;
  },
  wsLink,
  from([trackerApolloLink, persistedLink]),
);

/**
 * Client
 */
export const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: getEnv('VITE_APP_ENV') !== 'production',
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
