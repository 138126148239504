import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';

export interface ICommonSidebarContext {
  readonly?: boolean;
  hidden?: boolean;
}

export type ContextKey = keyof ISidebarContext;

export interface ISidebarState {
  active?: ContextKey;
  context: {
    [T in ContextKey]?: MergedContext<T>;
  };
  forceClose: boolean;
}

export type MergedContext<T extends ContextKey> = ICommonSidebarContext & Partial<ISidebarContext[T]>;

export const useSidebarStore = defineStore({
  id: 'sidebar',

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  state: () => ({
    context: {},
    active: undefined,
    forceClose: false,
  }) as ISidebarState,

  actions: {
    show<T extends ContextKey>(form: T, context?: MergedContext<T>) {
      if (useAuthStore().isHold && !context?.readonly) return;

      if (context) this.context[form] = cloneDeep(context);

      if (this.active && this.active !== form) {
        this.forceClose = true;
        // eslint-disable-next-line no-return-assign
        setTimeout(() => this.forceClose = false, 100);
      }

      this.active = form;
    },

    setReadonly(readonly: boolean) {
      if (!this.active) return;

      const context = this.context[this.active];

      if (context !== undefined) {
        // @ts-ignore: that's ok
        this.context[this.active] = {
          ...context,
          readonly,
        };
      }
    },

    addContext<T extends ContextKey>(form: T, context: Partial<MergedContext<T>>) {
      if (!this.active) return;

      const current = this.context[form];

      this.context[form] = {
        ...current,
        ...context,
      };
    },

    hide(preserveContext = false) {
      if (!preserveContext && this.active) this.context[this.active] = {};

      this.active = undefined;
    },
  },

  getters: {
    getContext() {
      const ctx = this.context;

      return <T extends ContextKey>(form: T): MergedContext<T> | undefined => ctx[form];
    },
  },
});
