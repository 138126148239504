import { CmeSettingsAdditionalTopic } from '@ml/shared';
import { find } from 'lodash-es';

import { phoneMasks } from '@/constants';
import { AddressPurpose, CmeSettingsGracePeriod, CmeSettingsRenewal, CmeSettingsRequirements, CmeSettingsReuqired, CmeSettingsType, Ethnicity, EyeColor, HairColor, HealthcarePayorsStatus, LicenseRequestType, LicenseStatus, LicenseType, LicenseVerificationStatus, ParseInterval, ParStatus, ProviderGender, ProviderStatus, ProviderSuffix, UserStatus } from '@/graphql';

import { createEnumUse } from '../util/enums';

// export { useCountries } from '../util/countries';

export const useAddressPurpose = createEnumUse<AddressPurpose>(AddressPurpose, {
  [AddressPurpose.W_9]: 'W-9',
  [AddressPurpose.PoBox]: 'PO Box',
});

export const useProviderStatus = createEnumUse(ProviderStatus, {
  [ProviderStatus.InProgress]: 'In Progress',
  [ProviderStatus.Rfa]: 'RFA',
});

export const useUserStatus = createEnumUse(UserStatus, {
  [UserStatus.NotInvited]: 'Not Invited',
});

export const useLicenseType = createEnumUse(LicenseType);
export const useLicenseStatus = createEnumUse(LicenseStatus);
export const useLicenseRequestType = createEnumUse(LicenseRequestType, {
  [LicenseRequestType.New]: 'New',
  [LicenseRequestType.Renew]: 'Renew',
  [LicenseRequestType.Reinstatement]: 'Reinstatement',
  [LicenseRequestType.AutoRenew]: 'Auto Renew',
});

export const useProviderSuffixes = createEnumUse(ProviderSuffix, {
  [ProviderSuffix.Jr]: 'Jr.',
  [ProviderSuffix.Sr]: 'Sr.',
  [ProviderSuffix.Ii]: 'II',
  [ProviderSuffix.Iii]: 'III',
  [ProviderSuffix.Iv]: 'IV',
  [ProviderSuffix.V]: 'V',
  [ProviderSuffix.Vi]: 'VI',
});

export const useProviderGenders = createEnumUse(ProviderGender, {
  [ProviderGender.Male]: 'Male',
  [ProviderGender.Female]: 'Female',
});

export const useEthnicity = createEnumUse(Ethnicity, {
  [Ethnicity.AfricanAmerican]: 'Black or African American',
  [Ethnicity.AmericanIndian]: 'American Indian or Alaska Native',
  [Ethnicity.Asian]: 'Asian',
  [Ethnicity.Latino]: 'Hispanic or Latino/a',
  [Ethnicity.PacificIslander]: 'Native Hawaiian or Other Pacific Islander',
  [Ethnicity.White]: 'White/Caucasian',
  [Ethnicity.NoAnswer]: 'Choose not to answer',
  [Ethnicity.Unknown]: 'Unknown',
  [Ethnicity.Other]: 'Other',
});

export const useParseInterval = createEnumUse(ParseInterval, {
  [ParseInterval.EveryFiveMin]: 'Every 5 min',
  [ParseInterval.EveryThirtyMin]: 'Every 30 min',
  [ParseInterval.EveryHour]: 'Every 1 hour',
  [ParseInterval.EveryFourHour]: 'Every 4 hours',
  [ParseInterval.EveryEightHour]: 'Every 8 hours',
  [ParseInterval.EveryTwentyHour]: 'Every 12 hours',
  [ParseInterval.Daily]: 'Daily',
  [ParseInterval.Weekly]: 'Weekly',
  [ParseInterval.Monthly]: 'Monthly',
});

export const useEyeColor = createEnumUse(EyeColor, {
  [EyeColor.Amber]: 'Amber',
  [EyeColor.Black]: 'Black',
  [EyeColor.Blue]: 'Blue',
  [EyeColor.Brown]: 'Brown',
  [EyeColor.Gray]: 'Gray',
  [EyeColor.Green]: 'Green',
  [EyeColor.Hazel]: 'Hazel',
  [EyeColor.Red]: 'Red',
  [EyeColor.Violet]: 'Violet',
});
export const useHairColor = createEnumUse(HairColor, {
  [HairColor.Bald]: 'Bald',
  [HairColor.Black]: 'Black',
  [HairColor.Blonde]: 'Blonde',
  [HairColor.Brown]: 'Brown',
  [HairColor.Gray]: 'Gray',
  [HairColor.Red]: 'Red',
  [HairColor.White]: 'White',
});
export const useVerificationStatus = createEnumUse(LicenseVerificationStatus, {
  [LicenseVerificationStatus.Active]: 'Verified',
  [LicenseVerificationStatus.NotVerified]: 'Not Verified',
  [LicenseVerificationStatus.Inactive]: 'Alert',
  [LicenseVerificationStatus.Failed]: 'Error',
});

export const useHealthcarePayorsStatus = createEnumUse(HealthcarePayorsStatus, {
  [HealthcarePayorsStatus.Active]: 'Active',
  [HealthcarePayorsStatus.InProgress]: 'In Progress',
  [HealthcarePayorsStatus.Inactive]: 'Inactive',
  [HealthcarePayorsStatus.OnHold]: 'On Hold',
  [HealthcarePayorsStatus.Other]: 'Other',
  [HealthcarePayorsStatus.Pending]: 'Pending',
});

export const useParStatus = createEnumUse(ParStatus);

export const useCmeSettingsReuqired = createEnumUse(CmeSettingsReuqired);
export const useCmeSettingsRenewal = createEnumUse(CmeSettingsRenewal, {
  [CmeSettingsRenewal.DateEvenYear]: 'Date / Even Year',
  [CmeSettingsRenewal.DateOddYear]: 'Date / Odd Year',
  [CmeSettingsRenewal.BirthDate]: 'Birth Date',
  [CmeSettingsRenewal.BirthMonth]: 'Birth Month',
  [CmeSettingsRenewal.BirthMonthLastDay]: 'Birth Month / Last Day',
  [CmeSettingsRenewal.IssueDate]: 'Issue Date',
  [CmeSettingsRenewal.IssueMonthFirstDay]: 'Issue Month / First Day',
});
export const useCmeSettingsGracePeriod = createEnumUse(CmeSettingsGracePeriod);
export const useCmeSettingsRequirements = createEnumUse(CmeSettingsRequirements);
export const useCmeSettingsType = createEnumUse(CmeSettingsType);
export const useCmeSettingsAdditionalTopic = createEnumUse(CmeSettingsAdditionalTopic);

export const getMaskByCode = (name: string): string => {
  const item = find(phoneMasks, {
    name,
  });
  if (!item) return phoneMasks[0].value;

  return item.value;
};
