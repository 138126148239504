export interface ToastOptions {
  time?: number
  text?: string
  title: string
  severity: ToastSeverity
}

export enum ToastSeverity {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}
