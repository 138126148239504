<template>
  <p-toast
    class="app-toast"
    v-bind="$attrs"
  >
    <template #message="props">
      <div class="app-toast-content">
        <span class="app-toast-content__icon">
          <app-icon
            :name="getIconName(props.message.severity)"
            :color="false"
          />
        </span>
        <h4 class="app-toast-content__title">
          {{ props.message.summary }}
        </h4>
        <p class="app-toast-content__details">
          {{ props.message.detail }}
        </p>
      </div>
    </template>
  </p-toast>
</template>

<script lang="ts" setup>
import { ToastSeverity } from '../models';

function getIconName(severity: ToastSeverity) {
  switch (severity) {
  case ToastSeverity.Success: { return 'check-circle';
  }
  case ToastSeverity.Info: { return 'info';
  }
  case ToastSeverity.Error: { return 'x';
  }
  default: { return '';
  }
  }
}
</script>

<style lang="scss">
  .app-toast-content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    width: 100%;
    padding: 5px 0;

    &__title {
      margin: 0;
      font-weight: 600;
    }

    &__icon {
      margin-right: 15px;
    }

    &__details {
      grid-column: 2;
      grid-row: 2;
      margin-bottom: 0;
      margin-top: -5px;
      font-weight: 400;
    }
  }

  .p-toast-message {
    &-success {
      .app-toast-content__icon {
        @include app-input-icon(var(--green-100), var(--green-50));
        stroke: var(--green-600);
      }
      .app-toast-content__title { color: var(--green-700); }
      .app-toast-content__details { color: var(--green-700); }
    }
    &-info {
      .app-toast-content__icon {
        @include app-input-icon(var(--primary-100), var(--primary-50));
        stroke: var(--primary-600);
      }
      .app-toast-content__title { color: var(--primary-700); }
      .app-toast-content__details { color: var(--primary-700); }
    }
    &-warn {
      .app-toast-content__icon {
        @include app-input-icon(var(--yellow-100), var(--yellow-50));
        stroke: var(--yellow-600);
      }
      .app-toast-content__title { color: var(--yellow-700); }
      .app-toast-content__details { color: var(--yellow-700); }
    }
    &-error {
      .app-toast-content__icon {
        @include app-input-icon(var(--red-100), var(--red-50));
        stroke: var(--red-600);
      }
      .app-toast-content__title { color: var(--red-700); }
      .app-toast-content__details { color: var(--red-700); }
    }
  }
</style>
